class Profile {
    constructor() {
        this.links = document.querySelectorAll(".td-profile .read-more");

        this.addEventListeners();
    }

    addEventListeners() {
        this.links.forEach((link) => {
            link.addEventListener("click", (e) => {
                e.preventDefault();

                let block = e.target.closest(".td-profile");
                block.classList.toggle("-js-show-full-profile");
            });
        });
    }
}

export default Profile;
